<template>
  <div class="container-fluid">
    <portal to="breadcrumb">
      <b-breadcrumb :items="breadcrumbs"></b-breadcrumb>
    </portal>

    <div class="px-4 pt-2 pb-5">
      <div class="bg p-3">
        <div class="d-flex flex-wrap mb-4 justify-content-between align-items-center">
          <div class="my-1 flex-fill">
            <slot name="listTitle">
              <h4>Liste des {{ referentielCaptilizedName }}s</h4>
            </slot>
          </div>
          <form class="d-flex my-1 me-2">
            <div class="input-group me-4">
              <input
                type="text"
                class="form-control"
                placeholder=""
                aria-label="Example text with button addon"
                aria-describedby="button-query"
              />
              <button type="submit" class="style_btn btn btn-primary" id="button-query">
                <i class="bi bi-search"></i>
              </button>
            </div>
          </form>
          <div class="dropdown me-3">
            <button
              :disabled="selected.length == 0"
              class="btn style_btn btn-primary dropdown-toggle"
              type="button"
              id="dropdownMenuButton1"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              Action des régions
            </button>
            <ul
              class="dropdown-menu dropdown-menu-end py-0"
              aria-labelledby="dropdownMenuButton1"
              style="margin: 0px"
            >
              <li class="border">
                <a class="dropdown-item" @click="destroyReferentiel" href="#"
                  >Supprimer</a
                >
              </li>
            </ul>
          </div>
          <div class="dropdown me-3">
            <button
              class="btn style_btn btn-primary dropdown-toggle"
              type="button"
              id="dropdownMenuButton1"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              Export
            </button>
            <ul class="dropdown-menu dropdown-menu-end py-0">
              <li class="border">
                <download-excel
                  class="btn btn-default"
                  :data="tableData"
                  :fields="tableFields"
                  worksheet="feuille 1"
                  :name="excelName"
                >
                  Excel
                </download-excel>
              </li>
            </ul>
          </div>

          <referentiel-editor
            ref="referentielEditor"
            @saved="updateOrCreateReferentiel"
            btnText="Créer un referentiel"
            :item="activeReferentiel"
            title="Ajouter un referentiel"
          >
            <template #formTitle>
              <slot name="formTitle">
                <h5>{{ formTitle }}</h5>
              </slot>
            </template>
            <template #formContent="{ handleInput, editableItem }">
              <slot
                name="formContent"
                :handleInput="handleInput"
                :editableItem="editableItem"
              >
                <div>
                  Veuillez définir le contenu du formulaire dans le slot 'formContent' de
                  RenferentielList
                </div>
              </slot>
            </template>
          </referentiel-editor>
        </div>
        <div class="bg-white p-3 border shadow">
          <div class="table-responsive">
            <b-table
              show-empty
              empty-text="Aucune donnée à afficher"
              :fields="tableFields"
              class="align-middle"
              striped
              select-mode="multi"
              ref="table"
              :sort-by.sync="mxPagination.sortBy"
              :sort-desc.sync="mxPagination.sortDesc"
              responsive="sm"
              selectable
              sort-icon-left
              @row-selected="onRowSelected"
              :items="tableData"
            >
              <template #head()="data">
                <span>{{ data.label }} </span>
              </template>

              <template #head(#)="data">
                <div class="form-check form-check-inline">
                  <input
                    @change="selectAll"
                    class="form-check-input"
                    type="checkbox"
                    :id="`${data.index}`"
                    name="disponible"
                    :value="false"
                  />
                </div>
              </template>
              <template #cell(#)="data">
                <div class="form-check form-check-inline">
                  <input
                    @input="selectionChanged($event, data)"
                    :checked="data.rowSelected"
                    class="form-check-input"
                    type="checkbox"
                    :id="`${data.index}`"
                    name="disponible"
                  />
                </div>
              </template>

              <!-- A virtual column -->
              <template #cell(index)="data">
                {{ data.index + 1 }}
              </template>
              <template #cell(enabled)="data">
                <div class="form-check form-switch">
                  <input
                    class="form-check-input"
                    v-model="data.item.enabled"
                    type="checkbox"
                    id="flexSwitchCheckChecked"
                    :checked="data.item.enabled"
                  />
                  <label class="form-check-label" for="flexSwitchCheckChecked">{{
                    data.item.enabled ? "Actif" : "Inactif"
                  }}</label>
                </div>
              </template>

              <template #cell(actions)="data">
                <div class="d-flex justify-content-end">
                  <a
                    @click.prevent="editReferentiel(data.item)"
                    class="btn btn-success rounded-0 btn-sm me-1"
                    ><i class="bi bi-pencil-square"></i
                  ></a>
                  <a class="btn btn-info rounded-0 btn-sm me-1"
                    ><i class="bi bi-info-square"></i
                  ></a>
                  <a
                    @click.prevent="destroyReferentiel(data.item)"
                    class="btn btn-danger rounded-0 btn-sm me-1"
                    ><i class="bi bi-trash"></i
                  ></a>
                </div>
              </template>
              <!-- Optional default data cell scoped slot -->
              <template #cell()="data">
                <span>{{ data.value }}</span>
              </template>
            </b-table>
          </div>
          <div class="d-flex flex-wrap justify-content-between align-items-center">
            <p>
              Affichage de l’élément
              {{ mxPagination.page * mxPagination.rowsPerPage }} à
              {{ mxPagination.page * mxPagination.rowsPerPage }} de
              {{ mxTotalCount }} éléments
            </p>
            <b-pagination
              v-model="mxPagination.page"
              :total-rows="mxTotalCount"
              :per-page="mxPagination.rowsPerPage"
              aria-controls="my-table"
            ></b-pagination>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import { mapActions } from 'vuex'
import { paginatorMixin } from "@/mixins/paginator-mixin";
import ReferentielEditor from "./ReferentielEditor.vue";

export default {
  props: {
    listTitle: {
      type: String,
      default: "Référentiel non précisé",
    },
    referentielName: {
      type: String,
      required: true,
    },
    formTitle: {
      type: String,
      required: true,
    },
    tableFields: {
      type: Array,
      required: true,
    },
    emptyReferentielItem: {
      type: Object,
      required: true,
    },
    dataFormatter: {
      type: Function,
    },
    paginatorConfig: {
      type: Object,
      default: () => {},
      required: true,
    },
  },
  components: {
    ReferentielEditor,
  },
  mixins: [paginatorMixin],
  data() {
    return {
      selected: [],
      search: "",
      filterOptions: [],
      excelName: "liste des " + this.referentielName + "s.xls",
      filters: {},
      activeReferentiel: { ...this.emptyReferentielItem },
      breadcrumbs: [
        {
          text: "Admin",
          href: "#",
        },
        {
          text: "Référentiels",
          href: "#",
        },
        {
          text: "Referentiels",
          active: true,
        },
      ],
    };
  },
  created() {
    this.mxInitializePaginator({
      queryModel: this.referentielName,
      search: this.search,
      fetcherMethod: `${this.referentielName}/fetch${this.referentielCaptilizedName}s`,
      dataGetter: `${this.referentielName}/${this.referentielName}s`,
      paginationGetter: `${this.referentielName}/pagination`,
      pagination: {
        sortBy: "libelle",
        sortDesc: false,
        descending: false,
        page: 0,
        rowsPerPage: 3,
      },
      filterOptions: this.filterOptions,
      searchFields: ["search"],
    });
  },
  computed: {
    tableData() {
      return this.mxFilteredItems.map((item) => {
        return { ...item };
      });
    },
    referentielCaptilizedName() {
      let lowerName = this.referentielName;
      return lowerName[0].toUpperCase() + lowerName.slice(1, lowerName.length);
    },
    referentielLowercaseName() {
      return this.referentielName; //Not use toLowerCase
    },
  },
  methods: {
    createOrUpdateReferentiel(params) {
      return this.$store.dispatch(
        `${this.referentielLowercaseName}/createOrUpdate${this.referentielCaptilizedName}`,
        params
      );
    },
    deleteReferentiel(params) {
      return this.$store.dispatch(
        `${this.referentielLowercaseName}/delete${this.referentielCaptilizedName}`,
        params
      );
    },
    selectAll(evt) {
      if (evt.target.checked) {
        this.$refs.table.selectAllRows();
      } else {
        this.$refs.table.clearSelected();
      }
    },
    selectionChanged(evt, data) {
      console.log(evt.target.checked, data);

      if (evt.target.checked) {
        this.$refs.table.selectRow(data.index);
      } else {
        this.$refs.table.unselectRow(data.index);
      }
    },
    onRowSelected(items) {
      this.selected = items;
    },
    editReferentiel(referentiel) {
      this.activeReferentiel = { ...referentiel };
      this.$emit("activeReferentielChange", this.activeReferentiel);
      this.$refs["referentielEditor"].show();
    },
    async destroyReferentiel(referentiel) {
      await this.$confirm({
        size: "md",
        centered: true,
        button: {
          no: "Non",
          yes: "Oui",
        },
        message: "Voulez-vous vraiment supprimer cet élément?",
        callback: (confirm) => {
          if (confirm) {
            this.deleteReferentiel(referentiel);
          }
        },
      });
    },
    updateOrCreateReferentiel(referentiel) {
      console.log(referentiel);
      let data = referentiel;

      if (this.dataFormatter instanceof Function) {
        data = this.dataFormatter(referentiel);
      }

      this.createOrUpdateReferentiel(data)
        .then(() => {
          this.$toast.success("Opération réussie!", {
            position: "top-right",
            duration: 5000,
          });
          this.activeReferentiel = { ...this.emptyReferentielItem };
        })
        .catch((err) => {
          this.$error.success(err.message, {
            position: "top-right",
            duration: 5000,
          });
        });
    },
  },
};
</script>

<style></style>
